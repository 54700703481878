import React, { useState, useEffect } from "react";

import WelcomeBanner from "../partials/dashboard/WelcomeBanner";
import Login from "../pages/Login";

import UserMenu from "../partials/header/UserMenu";

import axios from "axios";

import { Link } from "react-router-dom";

import Barchart from "../charts/BarChart";
import Linechart from "../charts/LineChart";
import Smsalert from "./Smsalert";
import ProgressChart from "../charts/ProgressChart";
import PieChart from "../charts/PieChart";
import Gauge1 from "../charts/Gauge1";
import GaugeChart from "react-gauge-chart";
import Gauge2 from "../charts/Gauge2";
import Thermometer from "react-thermometer-component";

function Dashboard() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isOn1, setIsOn1] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const SendString = localStorage.getItem("sendmsg");
  const sendmsg = eval("(" + SendString + ")");
  let msgon = { type: "control", id: 1, key: 1, value: 1 };
  let msgoff = { type: "control", id: 1, key: 1, value: 0 };
  const [ison, setIson] = useState(false);

  let [resultlatest, setResultLatest] = useState([]);
  let [mqttdata, setMqttData] = useState([]);

  let panelinfo = JSON.parse(localStorage.getItem("data"));
  // console.log(panelinfo);

  resultlatest = JSON.parse(localStorage.getItem("resultlatest"));
  mqttdata = JSON.parse(localStorage.getItem("mqttdata"));

  // console.log(resultlatest);

  setInterval(() => {
    let resultlatest = JSON.parse(localStorage.getItem("resultlatest"));
    let mqttdata = JSON.parse(localStorage.getItem("mqttdata"));
    setResultLatest(resultlatest);
    setMqttData(mqttdata);
    setIson(
      mqttdata?.[panelinfo.cardid]
        ? mqttdata[panelinfo.cardid][39] === 1
          ? true
          : false
        : false
    );
  }, 5000);
  // console.log(ison);

  const handleClick = () => {
    const confirmation = window.confirm(
      `Are you sure you want to switch ${
        ison === true ? "OFF" : "ON"
      } the pump?`
    );
    if (confirmation) {
      const message = ison ? msgoff : msgon;
      sendmsg(panelinfo.imei, message);
    }

    console.log("reached at pump status!, button clicked");
  };

  // console.log(mqttdata[panelinfo.cardid] ? mqttdata[panelinfo.cardid] : "0");

  // console.log("imei: ", panelinfo.imei, " ", "deviceid", panelinfo.deviceid);

  // console.log(mqttdata);

  /**apis starts here - analytical data */
  let loginToken = JSON.parse(localStorage.getItem("loginToken"));
  // console.log(loginToken);

  const handleChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const headers = {
    // Authorization: `Bearer ${loginToken}`,
    Authorization: "Bearer" + " " + loginToken,
    "Content-Type": "application/json",
  };
  const data = {
    device_id: panelinfo.deviceid,
    // date: "2023-04-27",
    date: selectedDate,
  };
  // console.log(data);

  let [timeArray, setTimeArray] = useState([]);
  let time = [];
  let msgArray = [];
  let message = [];
  let dataArr = [];
  let [powerArr, setPowerArr] = useState([]);
  let [vAvg, setVavg] = useState([]);
  let [iAvg, setIAvg] = useState([]);
  let [EnergyArr, setEnergyArr] = useState([]);
  let [avgPfArr, setAvgPfArr] = useState([]);
  let [alertArr, setAlertArr] = useState([]);
  let [alertTime, setAlertTime] = useState([]);
  let [chamb1temparr, setChamb1temparr] = useState([]);
  let [chamb2temparr, setChamb2temparr] = useState([]);
  let [chamb3temparr, setChamb3temparr] = useState([]);
  let [chamb4temparr, setChamb4temparr] = useState([]);

  function stringToArray(str) {
    const arr = str.split(",");
    return arr;
  }

  const [currentTime, setCurrentTime] = useState(
    new Date().toLocaleTimeString()
  );

  function convertTo24Hour(timeString) {
    const time = new Date("1970-01-01 " + timeString);
    let hours = time.getHours();
    const isPM = hours >= 12;
    hours = (hours % 12) + (isPM ? 12 : 0);
    const minutes = time.getMinutes().toString().padStart(2, "0");
    const seconds = time.getSeconds().toString().padStart(2, "0");
    const time24 =
      hours.toString().padStart(2, "0") + ":" + minutes + ":" + seconds;

    return time24;
  }
  // console.log(convertTo24Hour(currentTime));

  useEffect(() => {
    axios
      .post(
        "https://vidani.smart-iot.in/api/super-admin/device/getdevicelogs",
        data,
        { headers }
      )
      .then(function (response) {
        console.log(response.data.data);
        let x = response.data.data;
        let log = x.logs;
        // console.log(log[0].log_time);
        // console.log(logs.length);
        timeArray = [];
        for (let i = 0; i < log.length; i++) {
          //accessing log_time from the log (api)
          let y = log[i].log_time;
          time = new Date(y).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            timeZone: "Asia/Kolkata",
          });
          timeArray.push(convertTo24Hour(time));

          //accessing msg from the log (api)
          let w = log[i].msg;
          message.push(stringToArray(w));

          //to decrpt
          let array_index = 0;
          msgArray = [];
          for (let index = 0; index < 200; index++) {
            array_index = index * 2;
            msgArray[index] =
              Number(message[i][array_index]) * 256 +
              Number(message[i][array_index + 1]);

            dataArr[i] = msgArray;
            // console.log(dataArr[i]);
          }
        }
        setTimeArray(timeArray);
        // console.log(timeArray);
        // console.log(dataArr);
        for (let i = 0; i < dataArr.length; i++) {
          chamb1temparr[i] = dataArr[i][0] / 10;
          chamb2temparr[i] = dataArr[i][50] / 10;
          chamb3temparr[i] = dataArr[i][100] / 10;
          chamb4temparr[i] = dataArr[i][150] / 10;
          avgPfArr[i] = dataArr[i][30] / 100;
          powerArr[i] = dataArr[i][14];
          vAvg[i] = dataArr[i][3];
          iAvg[i] = dataArr[i][11];
          EnergyArr[i] = dataArr[i][12] * 65536 + dataArr[i][13];
          // alertArr[i] = [
          //   dataArr[i][61],
          //   dataArr[i][62],
          //   dataArr[i][63],
          //   dataArr[i][64],
          //   dataArr[i][65],
          //   dataArr[i][66],
          // ];
          alertArr[i] = [
            { vl1_fault: dataArr[i][61], time: timeArray[i] },
            { vl2_fault: dataArr[i][62], time: timeArray[i] },
            { vl3_fault: dataArr[i][63], time: timeArray[i] },
            { il1_fault: dataArr[i][64], time: timeArray[i] },
            { il2_fault: dataArr[i][65], time: timeArray[i] },
            { il3_fault: dataArr[i][66], time: timeArray[i] },
          ];

          // alertArr[i] = dataArr[i][61];
        }
        setVavg(vAvg);
        setIAvg(iAvg);
        setPowerArr(powerArr);
        setEnergyArr(EnergyArr);
        setAvgPfArr(avgPfArr);
        setAlertArr(alertArr);
        setChamb1temparr(chamb1temparr);
        setChamb2temparr(chamb2temparr);
        setChamb3temparr(chamb3temparr);
        setChamb4temparr(chamb4temparr);

        // console.log("hello from axios");
      })
      .catch(function (err) {
        console.log(err);
      });
  }, [selectedDate, panelinfo.deviceid]);
  // console.log(chamb1temparr);
  // console.log(chamb2temparr);
  // console.log(chamb3temparr);
  // console.log(chamb4temparr);

  // console.log(alertArr);
  let extractedFaults = [];
  for (let i = 0; i < alertArr.length; i++) {
    const faultObj = alertArr[i];
    const time1 = faultObj[0].time;
    if (
      faultObj[0].vl1_fault === 1 ||
      faultObj[1].vl2_fault === 1 ||
      faultObj[2].vl3_fault === 1
    ) {
      extractedFaults.push({ fault: "under voltage fault", time1 });
    }
    if (
      faultObj[0].il1_fault === 1 ||
      faultObj[1].il2_fault === 1 ||
      faultObj[2].il3_fault === 1
    ) {
      extractedFaults.push({ fault: "under current fault", time1 });
    }
    if (
      faultObj[0].vl1_fault === 2 ||
      faultObj[1].vl2_fault === 2 ||
      faultObj[2].vl3_fault === 2
    ) {
      extractedFaults.push({ fault: "Over Voltage fault", time1 });
    }
    if (
      faultObj[0].il1_fault === 2 ||
      faultObj[1].il2_fault === 2 ||
      faultObj[2].il3_fault === 2
    ) {
      extractedFaults.push({ fault: "Over Current fault", time1 });
    }
    if (
      faultObj[0].vl1_fault === 3 ||
      faultObj[1].vl2_fault === 3 ||
      faultObj[2].vl3_fault === 3
    ) {
      extractedFaults.push({ fault: "Imbalance voltage fault", time1 });
    }
    if (
      faultObj[0].il1_fault === 3 ||
      faultObj[1].il2_fault === 3 ||
      faultObj[2].il3_fault === 3
    ) {
      extractedFaults.push({ fault: "Imbalance current fault", time1 });
    }
  }
  // console.log(extractedFaults);
  if (extractedFaults) {
    localStorage.setItem("extractedFaults", JSON.stringify(extractedFaults));
  }

  let avgCvalueArray = [];
  let avgCtimeArray = [];

  let avgVvalueArray = [];
  let avgVtimeArray = [];

  let avgPFvalueArray = [];
  let avgPFtimeArray = [];

  let powervalueArray = [];
  let powertimeeArray = [];

  let energyvalueArray = [];
  let energytimeArray = [];

  let chamb1prunedArr = [];
  let chamb1prunedTime = [];
  let chamb2prunedArr = [];
  let chamb2prunedTime = [];
  let chamb3prunedArr = [];
  let chamb3prunedTime = [];
  let chamb4prunedArr = [];
  let chamb4prunedTime = [];

  // new optimized array
  for (let i = 0; i < timeArray.length / 5; i++) {
    let trueIndex = 0;

    trueIndex = i * 5;

    chamb1prunedArr.push(chamb1temparr[trueIndex]);
    chamb1prunedTime.push(timeArray[trueIndex]);

    chamb2prunedArr.push(chamb2temparr[trueIndex]);
    chamb2prunedTime.push(timeArray[trueIndex]);

    chamb3prunedArr.push(chamb3temparr[trueIndex]);
    chamb3prunedTime.push(timeArray[trueIndex]);

    chamb4prunedArr.push(chamb4temparr[trueIndex]);
    chamb4prunedTime.push(timeArray[trueIndex]);
  }
  // console.log(timeArray);
  // console.log(avgCvalueArray);
  // console.log(avgCtimeArray);

  // console.log(chamb1prunedArr);
  // console.log(chamb1prunedTime);

  let timeHour = [];
  let energyPerHour = [];

  for (let i = 0; i < EnergyArr.length - 1; i += 60) {
    const start = Math.max(0, i - 60);
    const end = Math.min(i + 60, EnergyArr.length - 1);
    energyPerHour.push(EnergyArr[end] - EnergyArr[start]);
    timeHour.push(timeArray[end]);
  }
  // console.log(timeHour);
  // console.log(energyPerHour);
  // console.log(mqttdata?.[panelinfo.cardid]?.[61]);
  // console.log(
  //   (mqttdata[panelinfo.cardid][12] * 65536 + mqttdata[panelinfo.cardid][13]) /
  //     1000
  // );

  let cham1temp = mqttdata?.[panelinfo.cardid]
    ? mqttdata[panelinfo.cardid][0]
    : 0;
  let cham2temp = mqttdata?.[panelinfo.cardid]
    ? mqttdata[panelinfo.cardid][50]
    : 0;

  let cham3temp = mqttdata?.[panelinfo.cardid]
    ? mqttdata[panelinfo.cardid][100]
    : 0;

  let cham4temp = mqttdata?.[panelinfo.cardid]
    ? mqttdata[panelinfo.cardid][150]
    : 0;

  return (
    <div className="flex h-screen bg-gradient-to-br from-black via-gray-800 to-blue-600 overflow-hidden">
      {/* Content area */}

      <div className="relative flex flex-col flex-1 overflow-x-hidden overflow-y-auto">
        <header className="sticky top-0 z-30 bg-black p-1  flex flex-wrap justify-end items-center gap-2 ">
          <div className="grid justify-start grid-flow-col gap-2 sm:auto-cols-max sm:justify-end">
            <label htmlFor="datepicker" className="sr-only">
              Select Date
            </label>
            <input
              type="date"
              id="datepicker"
              value={selectedDate}
              onChange={handleChange}
              className="px-3 py-2 mt-2 border rounded-lg sm:w-52 lg:w-56 md:w-60 xl:w-60 2xl:w-60"
            />
          </div>

          <div className="p-1">
            <UserMenu />
          </div>
        </header>

        <main className="p-0 m-0 ">
          <div className="w-full p-2 py-2 m-auto mb-2 sm:px-6 lg:px-8">
            {/* Welcome banner */}
            <WelcomeBanner />

            {/* statuses cards */}
            {/* <div className="bg-white bg-opacity-40 backdrop-filter backdrop-blur-lg border border-gray-300 border-opacity-50 rounded-lg shadow-md p-4 mb-2 flex flex-col flex-wrap  font-sans shadow-cyan-500">
              <div className="flex flex-wrap "></div>
            </div> */}
            {/* status ends here */}

            {/* //value Cards  */}
            <div className="bg-white bg-opacity-40 backdrop-filter backdrop-blur-lg border border-gray-300 border-opacity-50 rounded-lg shadow-md p-4 mb-2 flex flex-col flex-wrap  font-sans shadow-cyan-500">
              {/* voltage & phase voltage divs - 8 */}
              <div className="flex flex-wrap ">
                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-3 text-white bg-gradient-to-br from-cyan-800 to-cyan-600 hover:shadow-xl  f fle btn hover:bg-indigo transition duration-0 hover:duration-150 rounded-lg p-3">
                  <div className="font-bold m-2 mb-4 uppercase">
                    Incubator-1
                  </div>
                  <div>
                    <span className="font-bold text-xl">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][0] / 10
                        : 0}
                      ° C
                    </span>
                  </div>
                  <div>
                    <Thermometer
                      theme="dark"
                      value={cham1temp / 10}
                      max="200"
                      steps="1"
                      format="°C"
                      size="normal"
                      height="200"
                    />
                  </div>
                </div>
                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-3 text-white bg-gradient-to-br from-cyan-800 to-cyan-600 hover:shadow-xl  f fle btn hover:bg-indigo rounded-lg  p-3">
                  <div className="font-bold m-2 mb-4 uppercase">
                    Incubator-2
                  </div>
                  <div>
                    <span className="font-bold text-xl">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][50] / 10
                        : 0}
                      ° C
                    </span>
                  </div>
                  <div>
                    <Thermometer
                      theme="dark"
                      value={cham2temp / 10}
                      max="200"
                      steps="1"
                      format="°C"
                      size="normal"
                      height="200"
                    />
                  </div>
                </div>
                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-3 text-white bg-gradient-to-br from-cyan-800 to-cyan-600 hover:shadow-xl  f fle btn hover:bg-indigo rounded-lg p-3">
                  <div className="font-bold m-2 mb-4 uppercase">
                    Incubator-3
                  </div>
                  <div>
                    <span className="font-bold text-xl">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][100] / 10
                        : 0}
                      ° C
                    </span>
                  </div>
                  <div>
                    <Thermometer
                      theme="dark"
                      value={cham3temp / 10}
                      max="200"
                      steps="1"
                      format="°C"
                      size="normal"
                      height="200"
                    />
                  </div>
                </div>

                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-3 text-white bg-gradient-to-br from-cyan-800 to-cyan-600 hover:shadow-xl  f fle btn hover:bg-indigo rounded-lg p-3">
                  <div className="font-bold m-2 mb-4 uppercase">
                    Hot Air Oven
                  </div>
                  <div>
                    <span className="font-bold text-xl">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][150] / 10
                        : 0}
                      ° C
                    </span>
                  </div>
                  <div>
                    <Thermometer
                      theme="dark"
                      value={cham4temp / 10}
                      max="200"
                      steps="1"
                      format="°C"
                      size="normal"
                      height="200"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* value ends here */}

            <div className="flex mb-2 justify-center items-center flex-wrap gap-3 ">
              <div className="flex-1 bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg border border-gray-300 border-opacity-50  p-5  shadow-cyan-500  my-2 rounded-lg shadow-lg  ">
                <div className="text-center font-bold text-xl font-sans p-2">
                  Incubator-1 Trend
                </div>
                <Linechart
                  dataArr={chamb1prunedArr}
                  timeArr={chamb1prunedTime}
                  divider={1}
                  tag="Incubator-1 Temp"
                  colour="#38B2AC"
                  min={0}
                  max={10}
                  date={selectedDate}
                />
              </div>
              <div className="flex-1 bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg border border-gray-300 border-opacity-50  p-5  shadow-cyan-500  my-2  rounded-lg shadow-lg ">
                <div className="text-center font-bold text-xl font-sans p-2">
                  Incubator-2 Trend
                </div>
                <Linechart
                  dataArr={chamb2prunedArr}
                  timeArr={chamb2prunedTime}
                  divider={1}
                  tag="Incubator-2 Temp"
                  colour="#38B2AC"
                  min={0}
                  max={10}
                  date={selectedDate}
                />
              </div>
            </div>
            <div className="flex mb-2 flex-wrap justify-center items-center gap-3">
              <div className="flex-1 bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg border border-gray-300 border-opacity-50  p-5  shadow-cyan-500  my-2 rounded-lg shadow-lg ">
                <div className="text-center font-bold text-xl font-sans p-2">
                  Incubator-3 Trend
                </div>
                <Linechart
                  dataArr={chamb3prunedArr}
                  timeArr={chamb3prunedTime}
                  divider={1}
                  tag="Incubator-3 Temp"
                  colour="#38B2AC"
                  min={0}
                  max={10}
                  date={selectedDate}
                />
              </div>
              <div className="flex-1 bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg border border-gray-300 border-opacity-50  p-5  shadow-cyan-500  my-2 rounded-lg shadow-lg ">
                <div className="text-center font-bold text-xl font-sans p-2">
                  Hot Air Oven Trend
                </div>
                <Linechart
                  dataArr={chamb4prunedArr}
                  timeArr={chamb4prunedTime}
                  divider={1}
                  tag="Hot Air Oven Temp"
                  colour="#38B2AC"
                  min={0}
                  max={1}
                  date={selectedDate}
                />
              </div>
            </div>
            {/* <div className="flex mb-2 flex-wrap justify-center items-center gap-3">
              <div className="flex-1 bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg border border-gray-300 border-opacity-50  p-5  shadow-cyan-500  my-2 rounded-lg shadow-lg ">
                <div className="text-center font-bold text-xl font-serif p-2">
                  Energy<sub> / Hour</sub> Trend
                </div>
                <Barchart
                  dataArr={energyPerHour}
                  timeArr={timeHour}
                  divider={1}
                  tag="Energy"
                  colour="#38B2AC"
                  min={0}
                  max={10}
                  date={selectedDate}
                />
              </div>
            </div> */}
            {/* charts end here */}
          </div>
        </main>
      </div>
    </div>
  );
}

export default Dashboard;
